import { Link } from 'react-router-dom';

export default function index({ text, link = '/', icon }) {
	return (
		<Link to={link}>
			<button className='inline-flex items-center justify-center w-auto h-16 px-4 py-2 whitespace-nowrap border rounded-lg border-zinc-800 bg-zinc-700 hover:bg-blue-500 hover:ease-out duration-200 cursor-pointer text-base font-medium shadow-sm min-width: 140px'>
				<img src={icon} alt='Arrow point a door' className='w-6 h-6;' />
				<span className='ml-2'>{text}</span>
			</button>
		</Link>
	);
}
