import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import LogoImage from '../Logo';
import PrimaryButton from '../Buttons/PrimaryButton';
import DashboardIcon from '../../assets/icons/dashboard.svg';

export default function Header() {
	useEffect(() => {
		const burgerMenu = document.getElementById('burger-menu');
		const overlay = document.getElementById('menu');

		burgerMenu.addEventListener('click', function () {
			this.classList.toggle('close');
			overlay.classList.toggle('overlay');
		});
	});

	return (
		<div className='flex items-center justify-between px-2 sm:px-8 p-4 mx-auto border-b border-zinc-700'>
			{/* ===__===__===  Desktop menu ===__===__===  */}
			<div className='hidden sm:flex w-full items-center justify-between mx-auto'>
				<Link to='/'>
					<LogoImage />
				</Link>
				<div className='flex items-center gap-6'>
					<nav>
						<ul className='flex gap-6'>
							{/*                             
							<li className="hover:text-blue-500 hover:ease-out duration-200">
                                <Link to="/submitmywebsite">Submit your website</Link>
                            </li> 
							*/}
							<li className='hover:text-blue-500 hover:ease-out duration-200'>
								<Link to='/about'>About</Link>
							</li>
						</ul>
					</nav>
					<Link to='/dashboard'>
						<PrimaryButton
							text='Dashboard'
							icon={DashboardIcon}
							link='/dashboard'
						/>
					</Link>
				</div>
			</div>
			{/* ===__===__===  Mobile menu ===__===__===  */}
			<div className='sm:hidden'>
				<div id='burger-menu'>
					<span></span>
				</div>

				<div id='menu'>
					<ul>
						<li>
							<Link to='/dashboard'>Dashboard</Link>
						</li>
						<li>
							<Link to='/about'>About</Link>
						</li>
						{/* 						
						<li>
							<Link to='/submitmywebsite'>
								<button className='primary--button text-2xl sm:text-base'>
									Submit your website
								</button>
							</Link>
						</li> 
						*/}
					</ul>
				</div>
			</div>
		</div>
	);
}
