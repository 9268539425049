import { useEffect } from 'react';
import Card from '../Card';
import ComingSoonCard from '../ComingSoonCard';

/* === === === IMAGES IMPORTS === === === */

import Unsplash from '../../../assets/ListsAssets/Pictures/unsplash.png';
import Iconscout from '../../../assets/ListsAssets/Pictures/iconscout.png';

export default function PicturesList({ animation }) {
	/* === === === DISPLAY AND HOVER ANIMATION === === === */
	useEffect(() => {
		animation();
	}, []);
	return (
		<>
			<Card
				url='https://unsplash.com/fr'
				image={Unsplash}
				name='Unsplash'
				description='Unsplash is a popular online platform that provides a vast collection of high-quality, royalty-free images and photographs.'
			/>
			<Card
				url='https://iconscout.com/free-3ds'
				image={Iconscout}
				name='Iconscout - 3D'
				description='Iconscout offers a huge choice of assets of all kinds as many sites do, but they have a huge choice of free 3D assets and excellent quality.'
			/>
			<ComingSoonCard />
		</>
	);
}
