import React from 'react';
import LogoPNG from '../../assets/logo.png';

export default function LogoImage() {
	return (
		<img
			src={LogoPNG}
			alt='Logo'
			className='w-16 '
		/>
	);
}
