import React, { useEffect } from 'react';
import anime from 'animejs';
import SecondaryButton from '../components/Buttons/SecondaryButton';

/* ===__===__=== IMAGES IMPORTS ===__===__=== */

import BackImage from '../assets/icons/back.svg';

export default function About() {
	useEffect(() => {
		anime({
			targets: '.animation',
			opacity: [0, 1],
			easing: 'linear',
			duration: 1000,
			autoplay: true,
		});
	});

	return (
		<div className='animation flex justify-center items-center m-full sm:h-full'>
			<div className='flex flex-col gap-4 text-left md:w-4/5 lg:w-4/5 !max-w-screen-xl lg:p-10 p-6'>
				<h1 className='text-4xl'>About this website</h1>
				<div className='w-full h-1 mt-4 mb-6 bg-blue-500' />
				<p className='text-lg leading-6'>
					Hi! My name is Arthur and I am a freelance web developer for 3 years.
					<br />
					<br />
					I created this website to share with you all these amazing tools that people
					create to make our daily work easier.
					<br />
					<br />
					Instead of storing them in my favorites, might as well share them, right?
				</p>
				<h1 className='mt-14 text-4xl'>About me</h1>
				<div className='w-full h-1 mt-4 mb-6 bg-blue-500' />
				<ul className='flex gap-14 list-disc pl-5 mb-6'>
					<li className='w-fit text-lg leading-6 pb-4 hover:text-blue-500'>
						<a
							href='https://github.com/arthuroberlin'
							target='_blank'
							rel='noreferrer'>
							Github
						</a>
					</li>
					<li className='w-fit text-lg leading-6 hover:text-blue-500'>
						<a
							href='https://arthuroberlin.fr/'
							target='_blank'
							rel='noreferrer'>
							My portfolio
						</a>
					</li>
				</ul>
				<SecondaryButton
					text='Back'
					link='/'
					icon={BackImage}
				/>
			</div>
		</div>
	);
}
