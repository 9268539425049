import { Routes, Route } from 'react-router-dom';
import './style/MenuMobile.css';
import './style/App.css';

/* === === === VIEWS === === === */

import Index from './views/Index';
import Dashboard from './views/Dashboard';
import About from './views/About';
import _404 from './views/404';
/* 
import SubmitWebsite from './views/SendYourWebsite';
import Submitted from './views/Submited'; 
*/

/* ===__===__=== COMPONENTS ===__===__=== */

function App() {
	return (
		<div className='App h-screen'>
			<Routes>
				<Route
					path='/'
					element={<Index />}
				/>
				<Route
					path='dashboard'
					element={<Dashboard />}
				/>
				<Route
					path='about'
					element={<About />}
				/>
				{/* 				
        <Route
					path='submitmywebsite'
					element={<SubmitWebsite />}
				/>
				<Route
					path='thanksyou'
					element={<Submitted />}
				/> 
        */}
				<Route
					path='*'
					element={<_404 />}
				/>
			</Routes>
		</div>
	);
}

export default App;
