import { useState } from 'react';
import anime from 'animejs';
import Navbar from '../components/Navbar';
import UxList from '../components/Lists/UxUi';
import ToolsList from '../components/Lists/Tools';
import FontsList from '../components/Lists/Fonts';
import PicturesList from '../components/Lists/Pictures';
import IconsList from '../components/Lists/Icons';
import PackageList from '../components/Lists/Packages';

export default function Dashboard() {
	const [activeToggle, setActiveToggle] = useState({
		uxui: true,
		tools: false,
		fonts: false,
		pictures: false,
		icons: false,
		packages: false,
	});

	// ----- ----- Handle toggle list ----- ----- //
	const handleToggle = (toggleName) => {
		setActiveToggle((prevState) => ({
			...prevState,
			uxui: toggleName === 'uxui',
			tools: toggleName === 'tools',
			fonts: toggleName === 'fonts',
			pictures: toggleName === 'pictures',
			icons: toggleName === 'icons',
			packages: toggleName === 'packages',
		}));
	};

	const animateButton = (el, scale, duration, elasticity) => {
		anime.remove(el);
		anime({
			targets: el,
			scale: scale,
			duration: duration,
			elasticity: elasticity,
		});
	};

	const animateWebsiteLinks = () => {
		const websiteLinks = document.querySelectorAll('.website--link');
		const hoverWebsiteLinks = document.querySelectorAll('.website--link--hover');

		anime({
			targets: websiteLinks,
			opacity: [0, 1],
			translateY: ['-35px', '0'],
			easing: 'easeOutExpo',
			delay: anime.stagger(100),
			duration: 800,
			autoplay: true,
		}).finished.then(() => {
			hoverWebsiteLinks.forEach((el) => {
				el.addEventListener('mouseenter', () => {
					animateButton(el, 1.02, 800, 1000);
				});
				el.addEventListener('mouseleave', () => {
					animateButton(el, 1.0, 600, 1000);
				});
			});
		});
	};

	return (
		<div className='container flex flex-col md:flex-row items-center md:items-start gap-10 md:gap-0 md:h-[92%] pb-12 md:pb-0 m-auto pt-10 px-4 sm:px-2 overflow-x-hidden'>
			{/* Render Navbar component */}
			<div className='dashboard--navbar'>
				<Navbar
					handleToggle={handleToggle}
					activeToggle={activeToggle}
				/>
			</div>

			{/* Render list components based on activeToggle */}
			<div className='dashboard--list w-full h-fit overflow-visible md:h-full md:overflow-y-scroll md:ml-12 p-4 md:p-8 bg-zinc-700 rounded-lg'>
				{activeToggle.uxui && <UxList animation={animateWebsiteLinks} />}
				{activeToggle.tools && <ToolsList animation={animateWebsiteLinks} />}
				{activeToggle.fonts && <FontsList animation={animateWebsiteLinks} />}
				{activeToggle.pictures && <PicturesList animation={animateWebsiteLinks} />}
				{activeToggle.icons && <IconsList animation={animateWebsiteLinks} />}
				{activeToggle.packages && <PackageList animation={animateWebsiteLinks} />}
			</div>
		</div>
	);
}
