import { useEffect } from 'react';
import anime from 'animejs';
import Typewriter from 'typewriter-effect';
import Header from '../components/Header';

export default function Index() {
	useEffect(() => {
		anime({
			targets: '.animation',
			opacity: [0, 1],
			easing: 'linear',
			duration: 1000,
			autoplay: true,
		});
	});

	new Typewriter('#typewriter', {
		autoStart: true,
	});

	return (
		<>
			<div className='animation flex flex-col h-full'>
				<div>
					<Header />
				</div>
				<div className='container flex h-full m-auto'>
					<div className='flex flex-col justify-center items-center'>
						<div className='Typewriter-line flex flex-col justify-center -mt-14 mx-3 sm:mx-0 lg:items-center lg:gap-12 font-extrabold uppercase'>
							<h1 className='Typewriter--fixed Typewriter-line'>All the best</h1>
							<Typewriter
								options={{
									strings: [
										'tools',
										'fonts',
										'utilities',
										'pictures',
										'colors',
										'icons',
										'packages',
									],
									autoStart: true,
									loop: true,
									wrapperClassName: 'typewriter-container',
								}}
							/>
							<h1 className='Typewriter--fixed Typewriter-line'>
								web site for{' '}
								<span className='bg-blue-500 rounded-3xl p-1'>front-end</span>{' '}
								developer
							</h1>
						</div>
					</div>
				</div>
			</div>
			<div className='absolute bottom-5 left-5'>
				<span>Beta 1.1</span>
			</div>
			<div className='absolute bottom-5 right-5'>
				<p>
					Made with 💙 by {''}
					<a
						href='https://arthuroberlin.fr'
						target='_blank'
						className='underline hover:text-blue-500 hover:ease-out duration-200'
						rel='noreferrer'>
						Arthur
					</a>
				</p>
			</div>
		</>
	);
}
