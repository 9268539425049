import PrimaryButton from '../components/Buttons/PrimaryButton';
import BackImage from '../assets/icons/back.svg';
export default function _404() {
	return (
		<div className='h-full flex flex-col items-center justify-center gap-9'>
			<span>404 ERROR</span>
			<PrimaryButton
				text='Back to Home'
				link='/'
				icon={BackImage}
			/>
		</div>
	);
}
