import { useEffect } from 'react';
import Card from '../Card';
import ComingSoonCard from '../ComingSoonCard';

/* === === === IMAGES IMPORTS === === === */
import Flaticon from '../../../assets/ListsAssets/Icons/flaticon.png';
import Ionicons from '../../../assets/ListsAssets/Icons/ionicons.png';
import BunchOfIcons from '../../../assets/ListsAssets/Icons/bunchoficons.png';

export default function IconsList({ animation }) {
	/* === === === DISPLAY AND HOVER ANIMATION === === === */
	useEffect(() => {
		animation();
	}, []);
	return (
		<>
			<Card
				url='https://www.flaticon.com/'
				image={Flaticon}
				name='Flaticon'
				description="I think it's the most extensive site on this list, there are millions of icons and just as many different styles. If Flaticon doesn't have it, you'll have to create it yourself. I regret that more and more packs and other icons are paid for."
			/>
			<Card
				url='https://ionic.io/ionicons'
				image={Ionicons}
				name='Ionicons'
				description='Simple but effective, a collection of icons of different format, use through package or SVG. Enough for most projects.'
			/>
			<Card
				url='https://www.bunchof.design'
				image={BunchOfIcons}
				name='Bunch of icons'
				description="Really useful, easy integration but many paid icons, it's sad considering the quality of the website."
			/>
			<ComingSoonCard />
		</>
	);
}
