import { useEffect } from 'react';
import Card from '../Card';
import ComingSoonCard from '../ComingSoonCard';

/* ===__===__=== IMAGES IMPORTS ===__===__=== */

import Ptf from '../../../assets/ListsAssets/Fonts/ptf.png';
import Fontshare from '../../../assets/ListsAssets/Fonts/fontshare.png';

export default function FontsList({ animation }) {
	/* === === === DISPLAY AND HOVER ANIMATION === === === */
	useEffect(() => {
		animation();
	}, []);
	return (
		<>
			<Card
				url='https://www.poussetafonte.com/'
				image={Ptf}
				name='Pousse ta fonte'
				description='An incredible community website sharing design fonts and often very original, far from the classic Roboto and others.'
			/>
			<Card
				url='https://www.fontshare.com/'
				image={Fontshare}
				name='Fontshare'
				description='Brings together several fonts and even offers you peers to expand your possibilities!'
			/>
			<ComingSoonCard />
		</>
	);
}
