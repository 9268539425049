import { useEffect } from 'react';
import Card from '../Card';
import ComingSoonCard from '../ComingSoonCard';

/* === === === IMAGES IMPORTS === === === */

import Coolors from '../../../assets/ListsAssets/ux-ui/coolors.png';
import ColorHunt from '../../../assets/ListsAssets/ux-ui/colorhunt.png';
import CssGradient from '../../../assets/ListsAssets/ux-ui/cssGradient.png';
import Haikei from '../../../assets/ListsAssets/ux-ui/haikei.svg';
import Fffuel from '../../../assets/ListsAssets/ux-ui/fffuel.png';

export default function UxList({ animation }) {
	/* === === === DISPLAY AND HOVER ANIMATION === === === */
	useEffect(() => {
		animation();
	}, []);

	return (
		<>
			<Card
				url='https://coolors.co/'
				image={Coolors}
				name='Coolors'
				description='This tool will offer you an infinite choice of color palette for your design system, all generated by AI. Followed the tutorial to understand all the possibilities.'
			/>
			<Card
				url='https://colorhunt.co/'
				image={ColorHunt}
				name='Colorhunt'
				description="Unlike Coolors.io, this tool offers you already made color palettes and sort by likes and by theme. It's an excellent base, it's up to you to adapt them later."
			/>
			<Card
				url='https://cssgradient.io/'
				image={CssGradient}
				name='CSS Gradient'
				description='By far the best gradient creation tool I know. Very easy to use and lots of options.'
			/>
			<Card
				url='https://app.haikei.app/'
				image={Haikei}
				name='Haikei'
				description='Haikei is a web app that allows you to generate visuals such as Waves, Blob and any other essential element of modern design very easily, the application offers many options and is quite flexible in its use.'
			/>

			<Card
				url='https://fffuel.co/'
				image={Fffuel}
				name='fffuel'
				description='fffuel is a collection of color tools and free SVG generators for gradients, patterns, textures, shapes & backgrounds.It will come in handy in any modern project.'
			/>
			<ComingSoonCard />
		</>
	);
}
