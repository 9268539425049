export default function FontsList({ url, image, name, description }) {
	return (
		<a
			className='website--link website--link--hover'
			href={url}
			target='_blank'
			rel='noreferrer'>
			<img
				className='website--link__image'
				src={image}
				alt={name}
			/>
			<div className='flex flex-col justify-between w-full p-6 md:p-4 md:pl-6 leading-normal border-0 border-t md:border-t-0 md:border-l border-zinc-400'>
				<h3 className='mb-2 text-2xl text-center md:text-left font-bold tracking-tight text-white'>{name}</h3>
				<p className='mb-3 text-center md:text-left font-normal text-gray-400'>{description}</p>
			</div>
		</a>
	);
}