import { useEffect } from 'react';
import Card from '../Card';
import ComingSoonCard from '../ComingSoonCard';

/* === === === IMAGE IMPORT === === === */

import RemoveBG from '../../../assets/ListsAssets/Tools/removebg.png';
import Metatags from '../../../assets/ListsAssets/Tools/metatags.png';
import Readme from '../../../assets/ListsAssets/Tools/readme.png';

export default function ToolsList({ animation }) {
	/* === === === DISPLAY AND HOVER ANIMATION === === === */
	useEffect(() => {
		animation();
	}, []);

	return (
		<>
			<Card
				url='https://www.remove.bg/'
				image={RemoveBG}
				name='RemoveBG'
				description='A tool that removes backgrounds from your images. AI is good except in some very very close shades.'
			/>
			<Card
				url='https://metatags.io/'
				image={Metatags}
				name='Metatags'
				description='A handy tool to allow you to view meta tags in search to better prepare your deployment.'
			/>
			<Card
				url='https://readme.so/'
				image={Readme}
				name='Readme'
				description='Optimize your Github projects by editing and previewing your readme.md file.'
			/>
			<ComingSoonCard />
		</>
	);
}
