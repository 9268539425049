import { Link } from 'react-router-dom';
import uxui from '../../assets/icons/uxui.svg';
import tools from '../../assets/icons/tools.svg';
import fonts from '../../assets/icons/fonts.svg';
import pictures from '../../assets/icons/pictures.svg';
import icons from '../../assets/icons/icons.svg';
import packages from '../../assets/icons/packages.svg';

const NavLink = ({ handleToggle, icon, pathText, style }) => {
	const navClasses = `flex items-center w-full md:w-auto h-18 p-5 border-b border-zinc-800 hover:bg-blue-500 hover:ease-out duration-200 cursor-pointer ${style}`;
	return (
		<div
			className={navClasses}
			onClick={() => handleToggle()}>
			<img
				className='w-6 h-6'
				src={icon}
				alt='navbar icon who represents the menu label'
			/>
			<span className='ml-4'>{pathText}</span>
		</div>
	);
};

export default function Navbar({ handleToggle, activeToggle }) {
	const links = [
		{ name: 'UX / UI', icon: uxui, key: 'uxui' },
		{ name: 'Tools', icon: tools, key: 'tools' },
		{ name: 'Fonts', icon: fonts, key: 'fonts' },
		{ name: 'Pictures', icon: pictures, key: 'pictures' },
		{ name: 'Icons', icon: icons, key: 'icons' },
		{ name: 'Packages', icon: packages, key: 'packages' },
	];

	return (
		<>
			<div className='flex flew-row md:flex-col flex-wrap w-full md:w-44 bg-zinc-700 md:rounded-lg'>
				{links.map((link) => (
					<NavLink
						key={link.key}
						handleToggle={() => handleToggle(link.key)}
						style={activeToggle[link.key] ? 'bg-blue-500' : null}
						icon={link.icon}
						pathText={link.name}
					/>
				))}
			</div>
			<Link to='/'>
				<div className='flex items-center h-18 mt-10 p-5 border-b bg-zinc-700 rounded-lg border-zinc-800 hover:bg-blue-500 hover:ease-out duration-200 cursor-pointer'>
					<svg
						xmlns='http://www.w3.org/2000/svg'
						fill='none'
						viewBox='0 0 24 24'
						strokeWidth={1.5}
						stroke='currentColor'
						className='w-6 h-6'>
						<path
							strokeLinecap='round'
							strokeLinejoin='round'
							d='M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15M12 9l-3 3m0 0l3 3m-3-3h12.75'
						/>
					</svg>
					<span className='ml-4'>Back</span>
				</div>
			</Link>
		</>
	);
}
