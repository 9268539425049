import { useEffect } from 'react';
import ComingSoonCard from '../ComingSoonCard';

/* === === === IMAGES IMPORTS === === === */

export default function PackageList({ animation }) {
	/* === === === DISPLAY AND HOVER ANIMATION === === === */
	useEffect(() => {
		animation();
	}, []);
	return (
		<>
			<ComingSoonCard />
		</>
	);
}
